import React from 'react'
import LightboxLayout from '../../components/lightboxLayout'

const Page = () => {
  return (
    <LightboxLayout>
      <h2>SCALING AND POLISHING</h2>
      <h3>What is it?</h3>
      <p>Plaque is a soft, sticky substance that builds up on your teeth. It is mostly made up of bacteria. The bacteria in plaque feed on sugar from food and drink, producing acids as a waste product. The acids attack the teeth by dissolving the minerals in the tooth surface. If this happens too often, tooth decay results.</p>
      <p>And, if plaque  is allowed  to build up, the bacteria  in it can cause gum disease,  making  your gums sore and infected.</p>
      <p>Tartar (also known  as "calculus"), formed by hardened plaque, helps plaque to gather and makes it harder  to remove  when you brush. You can't remove  tartar just by brushing your teeth, but a dentist or hygienist can remove  it using special instruments - this is known  as scaling.</p>
      <p>A dentist or hygienist can also polish your teeth. This removes stains from coffee, tea, cigarettes or red wine; and it makes it more difficult for plaque to stick to your teeth.</p>
      <p>If you clean your teeth very thoroughly at home, your scale and polish treatment will take less time.</p>
      <h3>What happens?</h3>
      <p>Dentists  and hygienists use two types of instrument for scaling:</p>
      <ol>
        <li>Hand scalers  are metal scrapers that come in different  sizes and shapes,  to reach different  parts of the teeth. This is why you will see the dentist or hygienist changing instruments quite often.</li>
        <li>Electric scalers use a tip that vibrates very fast in a stream of water. The water is removed from your mouth using a small suction device. A hand scaler is also used along with an electric-scaler, to check whether the teeth are completely clean.</li>
      </ol>
      <p>For polishing, your dentist will use a rotating  brush or rubber polisher with a special toothpaste.</p>
      <p>Scaling  cleans above and below the gumline. If you have gum disease,  you may also need deeper scaling,  around the roots of the teeth, this is called "root planing".  Your dentist may give you a local anaesthetic for this.</p>
      <p>Your dentist or dental hygienist  will also tell you about the best way to clean your teeth and gums thoroughly  at home.</p>
      <h3>What are the benefits?</h3>
      <p>Regular  scaling and polishing by a dentist or hygienist help keep your teeth and gums healthy  by making  it easier for you to keep your teeth clean at home. You should see and feel the difference.</p>
      <p>If your gums  bleed  when  you brush, you may have  early gum disease. Regular scaling helps  to stop the disease getting worse;  and it helps  you to eliminate the disease by thorough cleaning at home.</p>
      <p>Bad breath is often caused  by gum disease.  Regular scaling, and cleaning your teeth thoroughly at home, can prevent  this.</p>
    </LightboxLayout>
  )
}

export default Page